import React, { useState } from "react"
import { graphql } from "gatsby"
import marked from "marked"
import cx from "classnames"
import Container from "components/Container"
import Layout from "components/Layout"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./Whitepaper.module.css"
import HubspotForm from "components/HubspotForm"

// eslint-disable-next-line
export const Input = ({ label, ...props }) => {
  const [isFocused, setFocus] = useState(false)
  const onFocus = () => setFocus(true)
  const onBlur = () => setFocus(false)

  return (
    <div
      className={cx(styles.input, {
        [styles.focused]: isFocused,
        [styles.valued]: props.value.trim() !== "",
        [styles.textarea]: props.type === "textarea",
      })}
    >
      <label>
        <span>{label}</span>
        {props.type === "textarea" ? (
          <textarea {...props} onFocus={onFocus} onBlur={onBlur} />
        ) : (
          <input type="text" {...props} onFocus={onFocus} onBlur={onBlur} />
        )}
      </label>
    </div>
  )
}

// eslint-disable-next-line
export const WhitepaperPage = ({ data, ...props }) => {

  return (
    <Layout {...props} {...data}>
      <div className={styles.preview}>
        <Container className={styles.previewcontainer}>
          <div>
            <h1>{data.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: marked(data.description || "") }} />
          </div>
          <div>
            <Img
              objectFit="contain"
              alt={data.title}
              title={data.title}
              fluid={data.image.childImageSharp.fluid}
            />
          </div>
        </Container>
      </div>

      <div className={styles.wrapper}>
        <Container>
          <div className={styles.formContainer}>
          <HubspotForm id="aa56f316-5eb5-4dff-a417-aa73b9700a7a" portalId="6237410" />
          </div>
          <div
            className={styles.subtext}
            dangerouslySetInnerHTML={{ __html: marked(data.form || "") }}
          />
        </Container>
      </div>
    </Layout>
  )
}

// eslint-disable-next-line
export const WhitepaperPageWrapper = ({ data, ...props }) => (
  <WhitepaperPage
    {...props}
    headerImage={data.headerImage}
    pinIcon={data.pinIcon}
    letterIcon={data.letterIcon}
    data={data.markdownRemark.frontmatter}
  />
)

export default WhitepaperPageWrapper

export const pageQuery = graphql`
  query WhitepaperQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "whitepaper" } }) {
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            extension
            publicURL
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        title
        description
        image {
          extension
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        form
      }
    }
  }
`
